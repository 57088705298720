import React, { useState, useCallback, useRef,  useEffect } from 'react';
import { GoogleMap, Polyline } from "@react-google-maps/api";
import { useHistory } from 'react-router-dom';
import './Polyline.css';
import NavBar from "../Navbar/Navbar.js";
import axiosInstance from '../axiosConfig';

const Polylines = () => {
    const mapRef = useRef();
    const history = useHistory();
    const defaultCityId = 1;
    const storedCityId = localStorage.getItem('cityId');
    const cityId = storedCityId ? parseInt(storedCityId, 10) : defaultCityId;
    const [currentLine, setCurrentLine] = useState([]);
    const defaultCityPath = { latitude: 41.3275, longitude: 19.8187 };
    const storedCityPath = localStorage.getItem('cityPath');
    const [line, setLine] = useState(null);
    const [streetName, setStreetName] = useState('');
    const [availableSlots, setAvailableSlots] = useState('');
    const [mapCenter] = useState(() => {
        let cityPath;
        try {
            cityPath = storedCityPath ? JSON.parse(storedCityPath) : defaultCityPath;
        } catch (error) {
            console.error("Error parsing cityPath from localStorage", error);
            cityPath = defaultCityPath;
        }
        return { lat: cityPath.latitude, lng: cityPath.longitude };
    });
    const options = {
        clickableIcons: false,
        disableDefaultUI: true,
        zoomControl: true,
        gestureHandling: "greedy"
    };


    const onMapClick = useCallback((event) => {
        if (!line) {  // Only allow drawing if there is no completed line
            const newPoint = { lat: event.latLng.lat(), lng: event.latLng.lng() };
            console.log("New point added:", newPoint);
            setCurrentLine(prev => {
                const updatedLine = [...prev, newPoint];
                console.log("Updated line:", updatedLine);
                return updatedLine;
            });
        }
    }, [line]);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.addListener('click', onMapClick);
        }
    }, [onMapClick]);

    const handleFinishLine = () => {
        if (currentLine.length > 1) {
            setLine({ points: currentLine });
            setCurrentLine([]); // Clear current line after setting
        }
    };

    const handleClearLine = () => {
        setLine(null); // Clear the completed line
        setCurrentLine([]); // Also clear any points that might be in currentLine
        setTimeout(() => window.location.reload(), 50);
    };

    const handleUndo = () => {
        setCurrentLine(currentLine.slice(0, -1));  // Remove the last point added to the line
    };

    const generateSlug = (name) => {
        return name.toLowerCase().replace(/\s+/g, '-');
    };

    const PostLine = async () => {
        const requestBody = {
            name: streetName,
            slug: generateSlug(streetName),
            path: line.points.map(point => ({ latitude: point.lat, longitude: point.lng })),
            available_slots: parseInt(availableSlots),
            ///city_id : cityId
        };
        console.log('Payload:',JSON.stringify(requestBody)); // Logging the entire body to console

        try {
            const response = await axiosInstance.post('admin/streets/create/', requestBody);
                console.log('Street created successfully',response.data);
                alert('Street created successfully!');
                history.push('/dashboardmap');
            }
            catch (error) {
                if (error.response) {
                    console.error('Error creating Street:', error.response.data);
                    alert('Error creating street!');
                } else if (error.request){
                    console.error('Error creating Street:', error.request);
                }
                else{
                    console.error('Error', error.message);
                }
                alert('Error creating Street!');
        }
    };

    

    return (
        <div className="container-map">
            <NavBar />
            <div className="controls">
                <h1>Create Street</h1>
                <input className='input-polyline-text'
                    type="text"
                    placeholder="Street Name"
                    value={streetName}
                    onChange={e => setStreetName(e.target.value)}
                />
                <input className='input-polyline-number'
                    type="number"
                    placeholder="Available Slots"
                    value={availableSlots}
                    onChange={e => setAvailableSlots(e.target.value)}
                />
                <button className="btn-finishline" onClick={handleFinishLine} disabled={currentLine.length < 2}>Finish Line</button>
                <button className="btn-undo" onClick={handleUndo} disabled={currentLine.length === 0}>Undo</button>
                 <button className="btn-clear" onClick={handleClearLine}>Clear Line</button>
                {line && (
                    <div className="line-details">
                        <p><strong>Street:</strong> {streetName}</p>
                        <p><strong>Available Slots:</strong> {availableSlots}</p>
                        <p><strong>City:</strong> Tirane</p>
                        <div className="active-street-status">
                        <strong>Active Status: Inactive</strong>
                        </div>
                        <div className="activation-message-street">
                        You can activate the street after creation.
                        The inactive street will be display as grey but it will return to red after activattion.
                        </div>
                    </div>
                )}
                {line && <button className="btn-saveline" onClick={PostLine}>Save</button>}
            </div>
            <div className="map">
                <GoogleMap
                    zoom={14}
                    center={mapCenter}
                    mapContainerClassName="container-map"
                    onLoad={map => {
                        mapRef.current = map;
                        mapRef.current.addListener('click', onMapClick);
                    }}
                    options={options}
                    onClick={onMapClick}
                >
                    {line && (
                        <Polyline
                            path={line.points}
                            options={{
                                strokeColor: "#FF0000",
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                clickable: false,
                                editable: false
                            }}
                        />
                    )}
                    {currentLine.length > 1 && (
                        <Polyline
                            path={currentLine}
                            options={{
                                strokeColor: "#00FF00",
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                clickable: false,
                                editable: false
                            }}
                        />
                    )}
                </GoogleMap>
            </div>
        </div>
    );
};

export default Polylines;
