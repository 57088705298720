import React, { useState, useEffect } from 'react';
import './ParkingSessions.css';
import * as XLSX from 'xlsx';
import Navbar from "../Navbar/Navbar.js";
////import axios from 'axios';
import axiosInstance from '../axiosConfig';

function Parkingsessions() {
    const [parkingSessions, setParkingSessions] = useState([]);
    const [filteredSessions, setFilteredSessions] = useState([]);
    const [filter, setFilter] = useState('');
    const [isActiveFilter, setIsActiveFilter] = useState('');
    const [isPaidFilter, setIsPaidFilter] = useState('');
    const [isAllDayFilter, setIsAllDayFilter] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [nextCursor, setNextCursor] = useState(null);
    const [prevCursor, setPrevCursor] = useState(null);

    useEffect(() => {
        fetchParkingSessions();
    }, []);

    const fetchParkingSessions = async (url = 'admin/parking/sessions/') => {
        try {
            /*const response = await axios.get(url, {
                headers: {
                    'Authorization': `Token 2c7a94b22a9f07b0ea177ace680cace3e4cc3f90`
                }
            });*/
            const response = await axiosInstance.get(url);
            // console.log(JSON.stringify(response));
            setParkingSessions(response.data.results);
            setFilteredSessions(response.data.results);
            setNextCursor(response.data.next);
            setPrevCursor(response.data.previous);
            console.log("next cursor",nextCursor);
            console.log("prev cursor",prevCursor);
        } catch (error) {
            if (error.response) {
                console.error('Error fetching parking sessions in response:', error.response.data);
            } else if (error.request) {
                console.error('Error fetching parking sessions in request:', error.request);
            } else {
                console.error('Error fetching parking sessions:', error.message);
            }
        }
    };

    const applyFilters = () => {
        let filtered = parkingSessions.filter(session => {
            const sessionDate = new Date(session.start_timestamp);
            const from = fromDate ? new Date(fromDate) : null;
            const to = toDate ? new Date(toDate) : null;

            return (
                (filter === '' || session.zone_data.zone.name.toLowerCase().includes(filter.toLowerCase())) &&
                (isActiveFilter === '' || session.is_active === (isActiveFilter === 'true')) &&
                (isPaidFilter === '' || session.payment.is_paid === (isPaidFilter === 'true')) &&
                (isAllDayFilter === '' || session.zone_data.is_all_day === (isAllDayFilter === 'true')) &&
                (!from || sessionDate >= from) &&
                (!to || sessionDate <= to)
            );
        });
        setFilteredSessions(filtered);
    };

    const clearFilters = () => {
        setFilter('');
        setIsActiveFilter('');
        setIsPaidFilter('');
        setIsAllDayFilter('');
        setFromDate('');
        setToDate('');
        setFilteredSessions(parkingSessions);
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString('en-US'); // Adjust the format as needed
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.table_to_sheet(document.querySelector('.table'));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'ParkingSessions');
        XLSX.writeFile(workbook, 'parking_sessions.xlsx');
    };

    return (
        <div className="container-parking">
            <div className="navbar-left-parking">
                <Navbar />
            </div>
            <div className='content-parking'>
                <div className='parking-header'>
                    <h1>Parking Sessions</h1>
                    <button className="export-to-excel-button" onClick={exportToExcel}>Export to Excel</button>
                </div>
                <div className="filters">
                    <div className="filter-row">
                        <div className="filter-group">
                            <label htmlFor="zoneName">Search by zone name</label>
                            <input
                                id="zoneName"
                                type="text"
                                placeholder="Search by zone name"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                        </div>
                        <div className="filter-group">
                            <label htmlFor="fromDate">From Date</label>
                            <input
                                id="fromDate"
                                type="date"
                                value={fromDate}
                                onChange={(e) => setFromDate(e.target.value)}
                            />
                        </div>
                        <div className="filter-group">
                            <label htmlFor="toDate">To Date</label>
                            <input
                                id="toDate"
                                type="date"
                                value={toDate}
                                onChange={(e) => setToDate(e.target.value)}
                            />
                        </div>
                        <button className="filter-button apply-button" onClick={applyFilters}>Apply</button>
                    </div>
                    <div className="filter-row">
                        <select value={isActiveFilter} onChange={(e) => setIsActiveFilter(e.target.value)} className="filter-select">
                            <option value="">All Sessions</option>
                            <option value="true">Active Sessions</option>
                            <option value="false">Inactive Sessions</option>
                        </select>
                        <select value={isPaidFilter} onChange={(e) => setIsPaidFilter(e.target.value)} className="filter-select">
                            <option value="">All Sessions</option>
                            <option value="true">Paid Sessions</option>
                            <option value="false">Unpaid Sessions</option>
                        </select>                   
                        <button className="filter-button clear-button" onClick={clearFilters}>Clear all</button>
                    </div>
                </div>
                <div className="table-container">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Zone Name</th>
                                <th>City</th>
                                <th>Vehicle</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Payment Method</th>
                                <th>Service Fee</th>
                                <th>Payment Total Amount</th>
                                <th>Active Session</th>
                                <th>Is Paid</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredSessions.map(session => (
                                <tr key={session.id}>
                                    <td>{session.id}</td>
                                    <td>{session.zone_data.zone.name}</td>
                                    <td>{session.zone_data.zone.city.name}</td>
                                    <td>{session.vehicle?.license_plate ? session.vehicle.license_plate : session.license_plate}</td>
                                    <td>{session.start_timestamp ? formatDate(session.start_timestamp) : ''}</td>
                                    <td>{session.end_timestamp ? formatDate(session.end_timestamp) : ''}</td>
                                    <td>{session.payment_method.name}</td>
                                    <td>{session.payment.service_fee ? session.payment.service_fee.amount : 0}</td>
                                    <td>{session.payment.total_amount}</td>
                                    <td className={session.is_active ? 'status-green' : 'status-red'}>
                                    {session.is_active ? <span>&#10004;</span> : <span>&#10006;</span>}
                                    </td>
                                    <td className={session.payment.is_paid ? 'status-green' : 'status-red'}>
                                    {session.payment.is_paid ? <span>&#10004;</span> : <span>&#10006;</span>}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="pagination-buttons">
                    {prevCursor && <button onClick={() => fetchParkingSessions(prevCursor)}>Previous</button>}
                    {nextCursor && <button onClick={() => fetchParkingSessions(nextCursor)}>Next</button>}
                </div>
            </div>
        </div>
    );
}

export default Parkingsessions;
