import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { GoogleMap, Marker } from "@react-google-maps/api";
import axiosInstance from '../axiosConfig';
import NavBar from "../Navbar/Navbar.js";
import '../CreatePin/CreatePin.css';

const EditPin = () => {
  const history = useHistory();
  const mapRef = useRef(null);
  const location = useLocation();
  const passedPin = location.state ? location.state.pin : null;
  const [markerPosition, setMarkerPosition] = useState(null);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [currency, setCurrency] = useState('ALL');
  const [availableSlots, setAvailableSlots] = useState('');
  const [website, setWebsite] = useState('');
  const storedCityPath = localStorage.getItem('cityPath');
  const defaultCityId = 1;
  const storedCityId = localStorage.getItem('cityId');
  const cityId = storedCityId ? parseInt(storedCityId, 10) : defaultCityId;
  const defaultCityPath = { latitude: 41.3275, longitude: 19.8187 };
  const [type, setType] = useState('PRIVATE');
  const dayOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const [mapCenter] = useState(() => {
    let cityPath;
    try {
        cityPath = storedCityPath ? JSON.parse(storedCityPath) : defaultCityPath;
    } catch (error) {
        console.error("Error parsing cityPath from localStorage", error);
        cityPath = defaultCityPath;
    }
    return { lat: cityPath.latitude, lng: cityPath.longitude };
    });

  const [timeSlots, setTimeSlots] = useState({
        Monday: [null, null],
        Tuesday: [null, null],
        Wednesday: [null, null],
        Thursday: [null, null],
        Friday: [null, null],
        Saturday: [null, null],
        Sunday: [null, null],
    }); 
  const handleTimeChange = (day, index, value) => {
        setTimeSlots(prev => ({
            ...prev,
            [day]: [
                ...prev[day].slice(0, index),
                value,
                ...prev[day].slice(index + 1)
            ]
        }));
    };
    const formatTime = (time) => {
        if (!time) return 'Free'; 
        return time; // Return the time as it comes from the API
      }

    useEffect(() => {
        if (passedPin && passedPin.coordinates) {
            setMarkerPosition({
                lat: passedPin.coordinates.lat,
                lng: passedPin.coordinates.lng,
              });
            console.log("Coordinates pin"+ passedPin.coordinates.latitude,passedPin.coordinates.longitude);  
            setName(passedPin.name);
            setCurrency(passedPin.price.currency);
            setPrice(passedPin.price.amount);
            setWebsite(passedPin.website);
            setAvailableSlots(passedPin.available_slots);
            setType(passedPin.type);
          //   const initialTimeSlots  = { };
          //   const timezone = passedPin.city.timezone_id;
          //   Object.entries(passedPin.price.rules).forEach(([day, { start_time, end_time }]) => {
          //     initialTimeSlots[day] = [
          //       formatTime(start_time, timezone),
          //       formatTime(end_time, timezone)
          //     ];
          // });
          const initialTimeSlots = dayOrder.reduce((acc, day) => {
            const dayData = passedPin.price.rules[day];
            return {
                ...acc,
                [day.charAt(0).toUpperCase() + day.slice(1)]: [
                    dayData ? formatTime(dayData.start_time) : null,
                    dayData ? formatTime(dayData.end_time) : null
                ]
            };
        }, {});
          setTimeSlots(initialTimeSlots);
        }
    }, [passedPin]);

  const handleMapClick = useCallback((event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });
  }, []);

  const generateSlug = (name) => {
    return name.toLowerCase().replace(/\s+/g, '-');
  };

  const PostPin = async (event) => {
    event.preventDefault();

    if (!markerPosition) {
      alert('Please place the marker on the map.');
      return;
    }
    const timeRules = Object.keys(timeSlots).reduce((acc, day) => {
      const dayLower = day.toLowerCase();
      return {
          ...acc,
          [dayLower]: {
              start_time: timeSlots[day][0],
              end_time: timeSlots[day][1]
          }
      };
    }, {});
    const requestBody = {
      name:name,
      type:type,
      slug:generateSlug(name),
      price: {
        amount:  parseInt(price, 10),
        currency,
        rules: timeRules
      },
      coordinates: {
        latitude: markerPosition.lat,
        longitude: markerPosition.lng,
      },
      available_slots: parseInt(availableSlots),
      website: website.startsWith('https://') ? website : 'https://' + website,
      is_active: false,
      ////city_id:cityId
    };
    console.log(JSON.stringify(requestBody));
    try {
      await axiosInstance.patch(`admin/pins/${passedPin.id}/`, requestBody);
      alert('Pin updated successfully!');
      history.push('/dashboardmap'); // Redirect to your desired page after successful creation
    } catch (error) {
      if (error.response) {
        console.error('Error updating pin:', error.response.data);
      } else if (error.request) {
        console.error('Error updating pin:', error.request);
      } else {
        console.error('Error:', error.message);
      }
      alert('Error updating pin!');
    }
  };

  return (
    <div className="container-pin-create">
      <NavBar />
      <div className="controls-pin-create">
                <h2>Place Pin information here</h2>
                <input className='input-pin-text'
                    type="text"
                    placeholder="Pin Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <input className='input-pin-number'
                    type="number"
                    placeholder="Price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}                   
                />
                <input className='input-pin-number'
                    type="number"
                    placeholder="Available Slots"
                    value={availableSlots}
                    onChange={(e) => setAvailableSlots(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Website"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                />
                <select value={type} onChange={(e) => setType(e.target.value)}>
                <option value="PRIVATE">Private Parking</option>
                <option value="FREE">Free Parking</option>
                <option value="ELECTRIC">Electric Charger</option>
                <option value="TERMINAL">Terminal</option>
                </select>
                <div className="active-pin-status">
                    <strong>Active Status: Inactive</strong>
                </div>
                    <div className="activation-message-pin">
                        You can activate the pin after creation.
                    </div>
                <div className="rules">
                    <h3>Rules:</h3>
                    {Object.entries(timeSlots).map(([day, times], index) => (
                        <div key={index}>
                            <label>{day.charAt(0).toUpperCase() + day.slice(1)} :</label>
                            <div>
                                <input
                                    type="time"
                                    value={times[0] || ""}
                                    onChange={e => handleTimeChange(day, 0, e.target.value)}
                                />
                                <input
                                    type="time"
                                    value={times[1] || ""}
                                    onChange={e => handleTimeChange(day, 1, e.target.value)}
                                />
                            </div>
                </div>
                    ))}
                <button className="btn-savepin" onClick={PostPin}>Save</button>
        </div>    
    </div>
        
    <div className="map">
        <GoogleMap
          zoom={14}
          center={mapCenter}
          mapContainerClassName="container-map"
          onClick={handleMapClick}
          onLoad={(map) => (mapRef.current = map)}
        >
          {markerPosition && <Marker position={markerPosition} />}
        </GoogleMap>
      </div>
    </div>
  );
}

export default EditPin;
