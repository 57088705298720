import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from './Login/Login';
import DashboardMap from './DashboardMap/DashboardMap';
import Parkingsessions from "./ParkingSessions/ParkingSessions.js"
import MapPageCreate from "./CreateMap/CreateMapDashboard.js"
import MapPageEdit from "./EditMap/EditMapDashboard.js"
import StatisticsPage from "./Statistics/Statistics.js"
import CreatePolylines from "./CreatePolyline/PolylineDashboard.js"
import EditPolyline from "./EditPolyline/EditPolylineDashboard.js"
import CreatePins from "./CreatePin/PinDashboard.js"
import EditPins from "./EditPin/EditPinDashboard.js"
import Logout from './Login/Logout.js';
import UserList from './Users/Users.js';
import AuthRoute from './AuthRoute';

function App() {
  return (
    // <AuthProvider>  {/* Wrap everything in AuthProvider */}
      <Router>
        <div className="App">
          <Switch>
            {/* <Route path="/login" component={Login} />
            <Route path="/dashboardmap" component={DashboardMap} />
            <Route path="/parkingsessions" component={Parkingsessions} />
            <Route path="/create-zone" component={MapPageCreate} />
            <Route path="/edit-zone/:id" component={MapPageEdit} />
            <Route path="/stats" component={StatisticsPage} />
            <Route path="/create-street" component={CreatePolylines} />
            <Route path="/logout" component={Logout} />  */}
            <Route path="/login" component={Login} />
            <AuthRoute path="/dashboardmap" component={DashboardMap} />
            <AuthRoute path="/parkingsessions" component={Parkingsessions} />
            <AuthRoute path="/create-zone" component={MapPageCreate} />
            <AuthRoute path="/edit-zone/:id" component={MapPageEdit} />
            <AuthRoute path="/edit-street/:id" component={EditPolyline} />
            <AuthRoute path="/edit-pin/:id" component={EditPins} />
            <AuthRoute path="/stats" component={StatisticsPage} />
            <AuthRoute path="/create-street" component={CreatePolylines} />
            <AuthRoute path="/create-pin" component={CreatePins} />
            <AuthRoute path="/users" component={UserList} />
            <AuthRoute path="/logout" component={Logout} /> 
            <Redirect from="/" to="/login" />
          </Switch>
        </div>
      </Router>
    // </AuthProvider>
  );
}

export default App;
