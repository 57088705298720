import React, { useState, useEffect, useRef,useMemo } from "react";
import { useHistory } from 'react-router-dom';
import "./CreateMap.css";
import NavBar from "../Navbar/Navbar.js"
import * as turf from '@turf/turf';
import { useLoadScript } from '@react-google-maps/api';
import axiosInstance from '../axiosConfig';
import { SketchPicker } from 'react-color';
// import { FaUndo } from 'react-icons/fa';


const generateHexagonCoordinates = (centerLat, centerLng, distance = 0.8) => {
    const center = turf.point([centerLng, centerLat]);
    const hexagon = turf.circle(center, distance, { steps: 6, units: 'kilometers' });
    const coordinates = hexagon.geometry.coordinates[0];
    
    // Convert coordinates back to lat/lng objects
    const hexagonCoordinates = coordinates.map(coord => ({ lat: coord[1], lng: coord[0] }));
    
    return hexagonCoordinates;
};


const MapCreate = () => {
    const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyA-z2alf2wbqaRxNAMcrGd8QqZ71wd0zo0" // Replace with your actual API key
    });
    const defaultCityId = 1;
    const defaultCityPath = [{ latitude: 41.3275, longitude: 19.8187 }];
    const storedCityId = localStorage.getItem('cityId');
    const storedCityPath = localStorage.getItem('cityPath');
    const cityId = storedCityId ? parseInt(storedCityId, 10) : defaultCityId;
    let cityPath;
    try {
      cityPath = storedCityPath ? JSON.parse(storedCityPath) : defaultCityPath;
    } catch (error) {
      console.error("Error parsing cityPath from localStorage", error);
      cityPath = defaultCityPath;
    }
    const { latitude, longitude } = cityPath;
    const history = useHistory();
    const mapRef = useRef();
    const polygonRef = useRef(null);
    const [zones, setZones] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [holeSelections, setHoleSelections] = useState([{ id: "", path: [] }]);
    const [color, setColor] = useState('#D70040');
    const hexagonPath = useMemo(() => generateHexagonCoordinates(latitude, longitude), [latitude, longitude]);
    const [path, setPath] = useState(hexagonPath);
    const [timeSlots, setTimeSlots] = useState({
        Monday: [null, null],
        Tuesday: [null, null],
        Wednesday: [null, null],
        Thursday: [null, null],
        Friday: [null, null],
        Saturday: [null, null],
        Sunday: [null, null],
    });
    const [polygonHistory, setPolygonHistory] = useState([]);

    const addHoleSelection = () => {
        setHoleSelections([...holeSelections, { id: "", path: [] }]);
    };

    const removeHoleSelection = (index) => {
        if (holeSelections.length > 1) {
            setHoleSelections(holeSelections.filter((_, i) => i !== index));
        } else {
            alert("At least one zone must be selected.");
        }
    };
    
    const handleZoneSelect =  (index,event) => {
        event.preventDefault();
        const newZoneId = event.target.value;
    const updatedHoleSelections = holeSelections.map((selection, idx) => {
        if (idx === index) {
            return { ...selection, id: newZoneId };
        }
        return selection;
    });
    setHoleSelections(updatedHoleSelections);

    // Fetch zone details based on selected ID
    const zone = zones.find(z => z.id.toString() === newZoneId);
    if (zone && polygonRef.current) {
        const holeCoordinates = zone.path.map(coord => [coord.longitude, coord.latitude]);

        // Ensure the coordinates form a closed loop
        if (holeCoordinates.length > 0 && (holeCoordinates[0][0] !== holeCoordinates[holeCoordinates.length - 1][0] ||
            holeCoordinates[0][1] !== holeCoordinates[holeCoordinates.length - 1][1])) {
            holeCoordinates.push(holeCoordinates[0]);
        }

        const holePolygon = turf.polygon([holeCoordinates]);
        const outerPolygonCoordinates = polygonRef.current.getPath().getArray().map(p => [p.lng(), p.lat()]);

        // Close the loop for outer polygon coordinates
        if (outerPolygonCoordinates.length > 0 && (outerPolygonCoordinates[0][0] !== outerPolygonCoordinates[outerPolygonCoordinates.length - 1][0] ||
            outerPolygonCoordinates[0][1] !== outerPolygonCoordinates[outerPolygonCoordinates.length - 1][1])) {
            outerPolygonCoordinates.push(outerPolygonCoordinates[0]);
        }

        const outerPolygon = turf.polygon([outerPolygonCoordinates]);

        try {
            const isValidHole = turf.booleanContains(outerPolygon, holePolygon);
            if (isValidHole) {
                console.log(`Zone ${zone.id} is a valid hole.`);
                // Optional: Trigger any additional UI update or state change
            } else {
                console.error(`Zone ${zone.id} is not a valid hole.`);
                alert(`Zone ${zone.name} cannot be added as a hole.`);
                // Reset the dropdown value for this particular dropdown
                updatedHoleSelections[index] = { ...updatedHoleSelections[index], id: "" };
                setHoleSelections(updatedHoleSelections);
            }
        } catch (error) {
            console.error("Error in creating polygons with Turf.js:", error);
            alert("Error processing the selected zone. Please check the console for details.");
            // Reset the dropdown value for this particular dropdown
            updatedHoleSelections[index] = { ...updatedHoleSelections[index], id: "" };
            setHoleSelections(updatedHoleSelections);
        }
    }
    };
    
    const handleTimeChange = (day, index, value) => {
            setTimeSlots(prev => ({
                ...prev,
                [day]: [
                    ...prev[day].slice(0, index),
                    value,
                    ...prev[day].slice(index + 1)
                ]
            }));
        };

    const handleButtonClick = async () => {
            const zoneName = document.querySelector('input[placeholder="Zone Name"]').value;
            const price = document.querySelector('input[placeholder="Price"]').value;
            const timeRules = Object.keys(timeSlots).reduce((acc, day) => {
                const dayLower = day.toLowerCase();
                return {
                    ...acc,
                    [dayLower]: {
                        start_time: timeSlots[day][0] || null,
                        end_time: timeSlots[day][1] || null
                    }
                };
            }, {});
            
    
            let zonePath = polygonRef.current.getPath().getArray().map(({ lat, lng }) => ({
                latitude: lat(),
                longitude: lng()
            }));
         
            // Check if the last coordinate matches the first; if not, add the first to the end to close the loop
            if (zonePath.length > 1 && (zonePath[0].latitude !== zonePath[zonePath.length - 1].latitude || zonePath[0].longitude !== zonePath[zonePath.length - 1].longitude)) {
                zonePath.push(zonePath[0]);
            }

            const holes = holeSelections
            .map(selection => zones.find(z => z.id.toString() === selection.id))
            .filter(zone => zone && zone.path)  // Ensure the zone and its path are defined
            .map(zone => zone.path.map(coord => ({
            latitude: coord.latitude,
            longitude: coord.longitude
            })));
            const requestBody = {
                name: zoneName,
                slug: zoneName.toLowerCase().replace(/\s+/g, '_'),
                price: {
                    amount: parseInt(price, 10),
                    currency: "ALL",
                    rules: timeRules
                },
                available_slots: 500,
                path: zonePath,
                holes: holes,
                is_active:false,
                ///city_id : cityId,
                color:color
            };
    
            // Log the complete request body for review
            console.log("Request Body:", JSON.stringify(requestBody));
            try {
                const response = await axiosInstance.post('admin/zones/create/', requestBody);
                console.log('Zone created successfully', response.data);
                alert('Zone created successfully!');
                history.push('/dashboardmap');

            } catch (error) {
            if (error.response) {
                console.error('Failed to create zone:', error.response.data);
            } else if (error.request) {      
                console.error('Error creating zone:', error.request);
            } else {
            console.error('Error', error.message);
            }
            alert('Error creating zone!');
            }
    };

    const handleUndo = () => {
        console.log("Undo button clicked");
        if (polygonHistory.length > 1) { // Ensure there's a previous state to revert to
            const previousPath = polygonHistory[polygonHistory.length - 2]; // Get the second last item
            console.log("Previous path from history:", previousPath);
            if (polygonRef.current) {
                const google = window.google;
                const newPath = previousPath.map(coord => new google.maps.LatLng(coord.lat, coord.lng));
                console.log("New Path LatLng objects:", newPath);
                polygonRef.current.setPath(newPath);
    
                // Verify if the path is set correctly
                const currentPath = polygonRef.current.getPath().getArray().map(latLng => ({
                    lat: latLng.lat(), lng: latLng.lng()
                }));
                console.log("Current polygon path after undo:", currentPath);
    
                // Remove the last entry from history after undo
                setPolygonHistory(polygonHistory.slice(0, -1));
            }
        } else {
            console.log("No more actions to undo.");
            alert("No more actions to undo.");
        }
    };

    useEffect(() => {
        const fetchZones = async () => {
            try {
              console.log("Fetching zones...");
              const response = await axiosInstance.get('/partner_entity/parking/');
                setZones(response.data.zones || []);
                setIsLoading(false);

            
            } catch (error) {
                console.error('Failed to fetch zones:', error);
                setError('Failed to load zones. Try again later.');
                setIsLoading(false);
            }
        };
  
        if (isLoaded) {
          fetchZones();
      }
    }, [isLoaded]);
    
    useEffect(() => {
        if (!isLoaded || loadError) return;

        const google = window.google;
        if (google) {
            const map = new google.maps.Map(mapRef.current, {
                center: { lat: latitude, lng: longitude },
                zoom: 14
            });

            polygonRef.current = new google.maps.Polygon({
                paths: hexagonPath,
                editable: true,
                draggable: true,
                fillColor: 'red',
                fillOpacity: 0.4,
                strokeColor: 'red',
                strokeOpacity: 1,
                strokeWeight: 2,
                zIndex: 2
            });

            setPolygonHistory([hexagonPath]);
            polygonRef.current.setMap(map);

            zones.forEach(zone => {
                const zonePolygon = new google.maps.Polygon({
                    paths: zone.path.map(point => ({ lat: point.latitude, lng: point.longitude })),
                    holes: zone.holes.map(hole => hole.map(point => ({ lat: point.latitude, lng: point.longitude }))),
                    editable: false,
                    draggable: false,
                    fillColor: 'grey',
                    fillOpacity: 0.1,
                    strokeColor: 'grey',
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    zIndex: 1
                });

                zonePolygon.setMap(map);
            });

            google.maps.event.addListener(polygonRef.current, 'mouseup', () => {
                const currentPath = polygonRef.current.getPath().getArray().map(latLng => ({
                    lat: latLng.lat(), lng: latLng.lng()
                }));

                setPolygonHistory(prev => {
                    const newHistory = [...prev, currentPath];
                    console.log("Updated polygon history:", newHistory);
                    return newHistory;
                });

                console.log("Polygon updated:", currentPath);
            });

            return () => {
                console.log("Cleaning up Google Maps...");
                if (polygonRef.current) {
                    polygonRef.current.setMap(null);
                }
                zones.forEach(zone => {
                    if (zone.polygon) {
                        zone.polygon.setMap(null);
                    }
                });
            };
        }
    }, [isLoaded, loadError, zones, hexagonPath, latitude, longitude]);

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading maps...</div>;

    return (
        <div className="container-map-create">
            <NavBar/>
            
            <div className="map-create" ref={mapRef}></div>
            <div className="controls-map-create">
                <h2>Place zone information here</h2>
                <input type="text" placeholder="Zone Name" />
                <input type="text" placeholder="Price" />
                <div className="active-status">
                    <strong>Active Status: Inactive</strong>
                </div>
                <div className="activation-message">
                    You can activate the zone after creation.
                    The inactive zone will be display as grey but they keep the colour you choose after activattion.
                </div>
                {holeSelections.map((selection, index) => (
                <div key={index} className="hole-selection-container">
                    <select
                        value={selection.id}
                        onChange={(event) => handleZoneSelect(index, event)}
                        disabled={isLoading}
                        className="hole-dropdown"
                    >
                        <option>{isLoading ? "Loading holes..." : "Select Sub-Zone"}</option>
                        {zones.map(zone => (
                            <option key={zone.id} value={zone.id}>{zone.name}</option>
                        ))}
                        {error && <option disabled>Error loading holes</option>}
                    </select>
                    <div className="hole-action-buttons">
                        <span className="add-hole-button" onClick={() => addHoleSelection()}>+</span>
                        {holeSelections.length > 1 && (
                            <span className="remove-hole-button" onClick={() => removeHoleSelection(index)}>-</span>
                        )}
                    </div>
                </div>
            ))}
                <div className="rules">
                    <h3>Rules:</h3>
                    {Object.entries(timeSlots).map(([day, times], index) => (
                        <div key={index}>
                            <label>{day}:</label>
                            <div>
                                <input
                                    type="time"
                                    value={times[0] || ""}
                                    onChange={e => handleTimeChange(day, 0, e.target.value)}
                                />
                                <input
                                    type="time"
                                    value={times[1] || ""}
                                    onChange={e => handleTimeChange(day, 1, e.target.value)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="color-picker">
                    <h4>Pick a color:</h4>
                    <div className="sketch-picker-wrapper">
                        <SketchPicker
                            color={color}
                            onChangeComplete={(newColor) => setColor(newColor.hex)}
                        />
                    </div>
                </div>
                <button className="undo-button-map" onClick={handleUndo}>
                    Undo
                </button>
                <button className="submit-save-button" onClick={handleButtonClick}>
                    Save
                </button>
            </div>
        </div>
    );
}

export default MapCreate;