import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { GoogleMap, Polygon, Polyline, Marker } from "@react-google-maps/api";
import axiosInstance from '../axiosConfig';
import './map.css';
import NavBar from "../Navbar/Navbar.js"
import { useHistory } from 'react-router-dom';
// import moment from 'moment-timezone';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import freepin from './images/FreePin.png';
import chargerpin from './images/ChargerPin.png';
import privatepin from './images/PrivetPin.png';

const MapZones = () => {
  const history = useHistory();
  const mapRef = useRef(null);
  const [selectedZone, setSelectedZone] = useState(null);
  const [loading, setLoading] = useState(true);
  const [zones, setZones] = useState([]);
  const [pins, setPins] = useState([]);
  const defaultCityPath = { latitude: 41.3275, longitude: 19.8187 };
  const storedCityPath = localStorage.getItem('cityPath');
  let cityPath;
  try {
    cityPath = storedCityPath ? JSON.parse(storedCityPath) : defaultCityPath;
  } catch (error) {
    console.error("Error parsing cityPath from localStorage", error);
    cityPath = defaultCityPath;
  }
  const center = useMemo(() => ({
    lat:  cityPath.latitude, 
    lng: cityPath.longitude
  }), [cityPath]);
  const mapStyles = [
    {
      featureType: "poi",
      elementType: "all",
      stylers: [{ visibility: "off" }]
    }
  ];
  const options = useMemo(() => ({
    clickableIcons: false,
    styles: mapStyles 
  }), []);  
  const [streets, setStreets] = useState([]);
  const [selectedStreet, setSelectedStreet] = useState(null);
  const [selectedPin, setSelectedPin] = useState(null);
  const [apiData,setApidata] = useState({});
  const [controlsVisible, setControlsVisible] = useState(false);
  const [mapWidth, setMapWidth] = useState('100%');
  const dayOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const typeMapping = {
    'PRIVATE': 'Private Parking',
    'FREE': 'Free Parking',
    'ELECTRIC': 'Electric Charger',
    'TERMINAL': 'Terminal'
  };
  const iconMapping = {
    'PRIVATE': { icon: privatepin, color: '#008080' },  
    'FREE': { icon: freepin, color: '#FFA903' },        
    'ELECTRIC': { icon: chargerpin, color: '#12B76A' }, 
    'TERMINAL': { icon: privatepin, color: '#008080' }  
  };

  const fetchZones = useCallback(async () => {
    try {
      ///const response = await axiosInstance.get('map/parking/');
      const response = await axiosInstance.get('/partner_entity/parking/');
      const updatedZones = response.data.zones.map((zone) => {
        return {
          ...zone,
          options: {
            ...zone.options,
            fillColor: zone.color,
            fillOpacity: 0.2,        
            strokeColor: '#000000',  
            strokeOpacity: 0.8,      
            strokeWeight: 2 
          },
          paths: flattenPathsAndHoles(zone)
        };
      });
      const updatedStreets = response.data.streets.map(street => ({
        ...street,
        path: street.path.map(coord => ({ lat: coord.latitude, lng: coord.longitude }))
      }));
      const updatedPins = response.data.pins.map(pin => ({
        ...pin,
        coordinates: {
          lat: parseFloat(pin.coordinates.latitude),
          lng: parseFloat(pin.coordinates.longitude)
        }
      }));
      setZones(updatedZones);
      setStreets(updatedStreets);
      setPins(updatedPins);
      setLoading(false);
    } catch (error) {
      if(error.response){
        console.error("Error fetching data in response:", error.response.data);
      }else if(error.request){
        console.error("Error fetching data in request:", error.response.data);
      }else{
        console.error("Error fetching data:", error.message);
      }
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchZones();
  }, [fetchZones]);

 function flattenPathsAndHoles(zone) {
  const paths = zone.path.map(coord => ({ lat: coord.latitude, lng: coord.longitude }));
  const holes = zone.holes.map(hole =>
      hole.map(coord => ({ lat: coord.latitude, lng: coord.longitude }))
  );
  // Return an array where the first element is the main path, followed by any holes
  return [paths, ...holes];

 }
  const handleZoneClick = useCallback((zone) => () => {
    setControlsVisible(true);
    setMapWidth('80%');
    setSelectedZone(zone);
    setSelectedStreet(null);
    setSelectedPin(null);
    setApidata(zone.price.rules);
  }, []);

  // const formatTime = (time, timezone) => {
  //   if (!time) return 'Free'; 
  //   // Check if timezone is valid and present
  //   if (timezone && moment.tz.zone(timezone)) {
  //     // Convert and format the time with timezone
  //     return moment.utc(time, "HH:mm").tz(timezone).format("HH:mm");
  //   } else {
  //     // Return the time in UTC format if no valid timezone is provided
  //     return moment.utc(time, "HH:mm").format("HH:mm");
  //   }
  // }

  const formatTime = (time) => {
    if (!time) return 'Free'; 
    return time; // Return the time as it comes from the API
  }
   
  const normalizedRules = dayOrder.reduce((acc, day) => {
    const dayData = apiData[day];
    return {
      ...acc,
      [day]: {
       start_time: dayData ? dayData.start_time : null,
        end_time: dayData ? dayData.end_time : null
      }
    };
  }, {});

  const handleEditZone = () => {
    if (selectedZone && selectedZone.id) {
      history.push({
       pathname: `/edit-zone/${selectedZone.id}`,
        state: {zone: selectedZone}
    });
    } else {
      alert('No zone selected for editing.');
    }
  };

  const handleDeleteZone = async () => {
    if (selectedZone && window.confirm("Are you sure you want to delete this zone?")) {
      try {
        await axiosInstance.delete(`admin/zones/${selectedZone.id}/`);
        // await axios.delete(`${baseURL}admin/zones/${selectedZone.id}/`, {
        //   headers: {
        //     'Authorization': `Token ${authToken}` 
        //   }
        // });
        ////setZones(zones.filter(zone => zone.id !== selectedZone.id));
        setSelectedZone(null);
        alert("Zone deleted successfully!");
        setTimeout(() => window.location.reload(), 100);
        ////history.push('/dashboardmap');
      }catch (error) {
        if (error.response) {
            console.error('Failed to delete zone:', error.response.data);
            alert(`Failed to delete zone.`);
        } else if (error.request) {      
            console.error('Failed to delete zone:', error.request);
        } else {
        console.error('Error', error.message);
        }
        alert('Failed to delete zone!');
        } 
      }
    
  };

  const handlePolylineClick = useCallback((street) => () => {
    setControlsVisible(true);
    setMapWidth('80%');
    setSelectedStreet(street);
    console.log(street);
    setSelectedZone(null);
    setSelectedPin(null);
  }, []);

  const handlePinsClick = useCallback((pin) => () => {
    setControlsVisible(true);
    setMapWidth('80%');
    setSelectedPin(pin);
    console.log(pin);
    setSelectedZone(null);
    setSelectedStreet(null);
    setApidata(pin.price.rules);
  }, []);

  const handleEditStreet = () =>
    {
      if (selectedStreet && selectedStreet.id) {
        history.push({
         pathname: `/edit-street/${selectedStreet.id}`,
          state: {street: selectedStreet}
      });
      } else {
        alert('No street selected for editing.');
      }
  };

  const handleMapClick = useCallback(() => {
    setControlsVisible(false);
    setMapWidth('100%');
    setSelectedZone(null);
    setSelectedStreet(null);
  }, []);

  const handleExportPDF = useCallback(() => {
    if (mapRef.current) {
      console.log('Map ref is set:', mapRef.current); // Logging for debugging
      html2canvas(mapRef.current, {
        useCORS: true,
        scale: 2, // Increase scale to capture a higher resolution image
      }).then((canvas) => {
        console.log('Canvas captured successfully'); // Logging for debugging
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('landscape');
        const imgWidth = 280;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight); // Adjust positioning and size as needed
        pdf.save('map.pdf');
      }).catch(error => {
        console.error('Error capturing the map:', error);
      });
    } else {
      console.error('Map ref is not set');
    }
  }, []);

  const handleDeleteStreet = async () => {
    if (selectedStreet && window.confirm("Are you sure you want to delete this street?")) {
      try {
        await axiosInstance.delete(`admin/streets/${selectedStreet.id}/`);
        setSelectedStreet(null);
        alert("Street deleted successfully!");
        setTimeout(() => window.location.reload(), 100);
        ///history.push('/dashboardmap');
      }catch (error) {
        if (error.response) {
            console.error('Failed to delete street:', error.response.data);
            alert(`Failed to delete street.`);
        } else if (error.request) {      
            console.error('Failed to delete street:', error.request);
        } else {
        console.error('Error', error.message);
        }
        alert('Failed to delete street!');
        } 
    }
  };

  const handleActiveateZone = async () =>{
    if (selectedZone && window.confirm("Are you sure you want to activate this zone?")) {
      try {
        await axiosInstance.post(`admin/zones/${selectedZone.id}/activate/`,{is_active: true});
        alert("Zone Activated successfully!");
        setTimeout(() => window.location.reload(), 100);
      }catch (error) {
        if (error.response) {
            console.error('Failed to activate zone:', error.response.data);
        } else if (error.request) {      
            console.error('Failed to activate zone:', error.request);
        } else {
        console.error('Error', error.message);
        }
        alert('Failed to activate zone!');
        } 
    }
  };

  const handleActiveateStreet = async () =>{
    if (selectedStreet && window.confirm("Are you sure you want to activate this street?")) {
      try {
        await axiosInstance.post(`admin/streets/${selectedStreet.id}/activate/`,{is_active: true});
        alert("Street Activated successfully!");
        setTimeout(() => window.location.reload(), 100);
      }catch (error) {
        if (error.response) {
            console.error('Failed to activate street:', error.response.data);
        } else if (error.request) {      
            console.error('Failed to activate street:', error.request);
        } else {
        console.error('Error', error.message);
        }
        alert('Failed to activate street!');
        } 
    }
  };

  const handleDeactivateZone = async () =>{
    if (selectedZone && window.confirm("Are you sure you want to deactivate this zone?")) {
      try {
        await axiosInstance.post(`admin/zones/${selectedZone.id}/activate/`,{is_active: false});
        alert("Zone Deactivated successfully!");
        setTimeout(() => window.location.reload(), 100);
      }catch (error) {
        if (error.response) {
            console.error('Failed to deactivate zone:', error.response.data);
        } else if (error.request) {      
            console.error('Failed to deactivate zone:', error.request);
        } else {
        console.error('Error', error.message);
        }
        alert('Failed to deactivate zone!');
        } 
    }
  };

  const handleDeactivateStreet = async () =>{
    if (selectedStreet && window.confirm("Are you sure you want to deactivate this street?")) {
      try {
        await axiosInstance.post(`admin/streets/${selectedStreet.id}/activate/`,{is_active: false});
        alert("Street Deactivated successfully!");
        setTimeout(() => window.location.reload(), 100);
      }catch (error) {
        if (error.response) {
            console.error('Failed to deactivate street:', error.response.data);
        } else if (error.request) {      
            console.error('Failed to deactivate street:', error.request);
        } else {
        console.error('Error', error.message);
        }
        alert('Failed to deactivate street!');
        } 
    }
  };

  const handleEditPin = async () => {
      if (selectedPin && selectedPin.id) {
        console.log(selectedPin);
        history.push({
         pathname: `/edit-pin/${selectedPin.id}`,
          state: {pin: selectedPin}
      });
      } else {
        alert('No pin selected for editing.');
      }
    
  };

  const handleDeletePin = async () => {
    if (selectedPin && window.confirm("Are you sure you want to delete this pin?")) {
      try {
        await axiosInstance.delete(`admin/pins/${selectedPin.id}/`);
        selectedPin(null);
        alert("Pin deleted successfully!");
        setTimeout(() => window.location.reload(), 100);
      }catch (error) {
        if (error.response) {
            console.error('Failed to delete pin:', error.response.data);
            alert(`Failed to delete pin.`);
        } else if (error.request) {      
            console.error('Failed to delete pin:', error.request);
        } else {
        console.error('Error', error.message);
        }
        alert('Failed to delete pin!');
        } 
    }
  };

  const handleDeactivatePin = async () => {
    if (selectedPin && window.confirm("Are you sure you want to deactivate this pin?")) {
      try {
        await axiosInstance.post(`admin/pins/${selectedPin.id}/activate/`,{is_active: false});
        alert("Pin Deactivated successfully!");
        setTimeout(() => window.location.reload(), 100);
      }catch (error) {
        if (error.response) {
            console.error('Failed to deactivate pin:', error.response.data);
        } else if (error.request) {      
            console.error('Failed to deactivate pin:', error.request);
        } else {
        console.error('Error', error.message);
        }
        alert('Failed to deactivate pin!');
        } 
    }
  };

  const handleActiveatePin = async () => {
    if (selectedPin && window.confirm("Are you sure you want to activate this pin?")) {
      try {
        await axiosInstance.post(`admin/pins/${selectedPin.id}/activate/`,{is_active: true});
        alert("Pin Activated successfully!");
        setTimeout(() => window.location.reload(), 100);
      }catch (error) {
        if (error.response) {
            console.error('Failed to activate pin:', error.response.data);
        } else if (error.request) {      
            console.error('Failed to activate pin:', error.request);
        } else {
        console.error('Error', error.message);
        }
        alert('Failed to activate pin!');
        } 
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container-map">
      <div>
        <NavBar handleTheClick={handleExportPDF} />
      </div>
      {controlsVisible && (
      <div className="controls">
        {selectedZone && (
          <>
            <h1>Zone Details</h1>
            <div className="zone-card">
              <p><strong>Name:</strong> {selectedZone.name}</p>
              <p><strong>Price:</strong> {selectedZone.price.amount} {selectedZone.price.currency}</p>
              <p><strong>Active:</strong> {selectedZone.is_active ? 'True' : 'False'}</p>
              <p><strong>City:</strong> {selectedZone.city ? selectedZone.city.name : 'No City'}</p>
            </div>
            <div className="rules-card">
              <h2>Rules:</h2>
              <ul>
                {Object.entries(normalizedRules).map(([day, rule]) => {
                  const startTime = formatTime(rule.start_time);
                  const endTime = formatTime(rule.end_time);
                  const displayTime = startTime === 'Free' && endTime === 'Free' ? 'Free' : `${startTime} - ${endTime}`;
                  return (
                    <li key={day}>
                      <strong>{day.charAt(0).toUpperCase() + day.slice(1)} : </strong>
                      {displayTime}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="actions-card">
              <button className="edit-zone-button" onClick={handleEditZone}>Edit Zone</button>
              <button className="delete-zone-button" onClick={handleDeleteZone}>Delete Zone</button>
              <button
                className="activate-zone-button"
                onClick={selectedZone.is_active ? handleDeactivateZone : handleActiveateZone}
              >
                {selectedZone.is_active ? "Deactivate Zone" : "Activate Zone"}
              </button>
            </div>
          </>
        )}
        {selectedStreet && (
          <>
            <h1>Street Details</h1>
            <div className="zone-card">
              <p><strong>Name:</strong> {selectedStreet.name}</p>
              <p><strong>Available Slots:</strong> {selectedStreet.available_slots}</p>
              <p><strong>City:</strong> {selectedStreet.city ? selectedStreet.city.name : 'No City'}</p>
            </div>
            <div className="actions-card">
              <button className="edit-street-button" onClick={handleEditStreet}>Edit Street</button>
              <button className="delete-street-button" onClick={handleDeleteStreet}>Delete Street</button>
              <button
                className="activate-street-button"
                onClick={selectedStreet.is_active ? handleDeactivateStreet : handleActiveateStreet}
              >
                {selectedStreet.is_active ? "Deactivate Street" : "Activate Street"}
              </button>
            </div>
          </>
        )}
        {selectedPin && (
          <>
            <h1>Pin Details</h1>
            <div className="pin-card">
              <p><strong>Name:</strong> {selectedPin.name}</p>
              <p><strong>Price:</strong> {selectedPin.price.amount} {selectedPin.price.currency}</p>
              <p><strong>Active:</strong> {selectedPin.is_active ? 'True' : 'False'}</p>
              <p><strong>Type:</strong> {typeMapping[selectedPin.type]}</p>
              <p><strong>Website:</strong> <a href={selectedPin.website} target="_blank" rel="noopener noreferrer">{selectedPin.website}</a></p>
              <p><strong>City:</strong> {selectedPin.city ? selectedPin.city.name : 'No City'}</p>
            </div>
            <div className="rules-card">
              <h2>Rules:</h2>
              <ul>
                {Object.entries(normalizedRules).map(([day, rule]) => {
                  const startTime = formatTime(rule.start_time);
                  const endTime = formatTime(rule.end_time);
                  const displayTime = startTime === 'Free' && endTime === 'Free' ? 'Free' : `${startTime} - ${endTime}`;
                  return (
                    <li key={day}>
                      <strong>{day.charAt(0).toUpperCase() + day.slice(1)} : </strong>
                      {displayTime}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="actions-card">
              <button className="edit-pin-button" onClick={handleEditPin}>Edit Pin</button>
              <button className="delete-pin-button" onClick={handleDeletePin}>Delete Pin</button>
              <button
                className="activate-pin-button"
                onClick={selectedPin.is_active ? handleDeactivatePin : handleActiveatePin}
              >
                {selectedPin.is_active ? "Deactivate Pin" : "Activate Pin"}
              </button>
            </div>
          </>
        )}
        </div>)}
      <div className="map" ref={mapRef} style={{ width: mapWidth }}>
        <GoogleMap
          zoom={14}
          center={center}
          mapContainerClassName="container-map"
          options={options}
          onLoad={map => {
            mapRef.current = map.getDiv();
            console.log('Map loaded:', mapRef.current); // Logging for debugging
          }}
          onClick={handleMapClick}
        >
          {zones.map((zone) => (
            <Polygon
              key={`zone-${zone.id}`}
              paths={flattenPathsAndHoles(zone)}
              ////paths={paths}
              options={{
                fillColor: zone.is_active ? zone.options.fillColor : '#808080',
                fillOpacity: zone.options.fillOpacity,
                strokeColor: zone.options.strokeColor,
                strokeOpacity: zone.options.strokeOpacity,
                strokeWeight: zone.options.strokeWeight,  
              }}
              onClick={handleZoneClick(zone)}
            >
            </Polygon>
          ))}
            {streets.map((street) => (
            <Polyline
            key={`street-${street.id}`}
            path={street.path}
            options={{
              strokeColor: street.is_active ? '#BF2310' : '#808080',
              strokeOpacity: 0.8,
              strokeWeight: 6,
              clickable: true,
              zIndex: 1000
            }}
            onClick={handlePolylineClick(street)}
          />
          ))}
          {pins.map((pin) => (
            <Marker
              key={`pin-${pin.id}`}
              position={{ lat: pin.coordinates.lat, lng: pin.coordinates.lng }}
              icon={{
                url: iconMapping[pin.type].icon,
                scaledSize: new window.google.maps.Size(33, 35), 
                labelOrigin: new window.google.maps.Point(15, 40) 
              }}
              label={{
                text:  pin.name, 
                color: iconMapping[pin.type].color, 
                fontSize: '10px', 
                fontWeight: 'bold' 
              }}
              onClick={handlePinsClick(pin)}
            />
          ))}
        </GoogleMap>
      </div>
    </div>
);


};

export default MapZones;