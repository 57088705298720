import { useLoadScript } from '@react-google-maps/api';
import MapCreate from "./CreateMap.js"


export default function MapPageCreate(){
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyA-z2alf2wbqaRxNAMcrGd8QqZ71wd0zo0",

    });

    if(!isLoaded) return <div>Loading....</div>;
    return (
        <div>
            {isLoaded ? (
                <div>
                    <MapCreate />
                </div>
            ) : (
                <div>Loading....</div>
            )}
        </div>
    );
}
