import React, { useState, useEffect, useRef } from "react";
import "./EditMap.css";
import { useHistory } from 'react-router-dom';
import NavBar from "../Navbar/Navbar.js"
import { useLocation } from 'react-router-dom';
import { useLoadScript } from '@react-google-maps/api';
import * as turf from '@turf/turf';
///import moment from 'moment-timezone';
import { SketchPicker  } from 'react-color';
import axiosInstance from '../axiosConfig';

const MapEdit = () => {
    const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyA-z2alf2wbqaRxNAMcrGd8QqZ71wd0zo0" // Replace with your actual API key
    });
    const location = useLocation();
    const history = useHistory();
    const defaultCityPath = [{ latitude: 41.3275, longitude: 19.8187 }];
    const storedCityPath = localStorage.getItem('cityPath');
    let cityPath;
    try {
      cityPath = storedCityPath ? JSON.parse(storedCityPath) : defaultCityPath;
    } catch (error) {
      console.error("Error parsing cityPath from localStorage", error);
      cityPath = defaultCityPath;
    }
    const { latitude, longitude } = cityPath;
    const passedZone = location.state ? location.state.zone : null;
    const [holeSelections, setHoleSelections] = useState([{ id: "", path: [] }]);
    const [isLoading, setIsLoading] = useState(true);
    const [zones, setZones] = useState([]);
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [currency, setCurrency] = useState('ALL');
    const mapRef = useRef();
    const polygonRef = useRef(null);
    const [color, setColor] = useState('#D70040');
    const dayOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    const [path, setPath] = useState([
        { lat: 41.333426, lng: 19.804751 },
        { lat: 41.336076, lng: 19.816752 },
        { lat: 41.340434, lng: 19.828674 },
        { lat: 41.330776, lng: 19.834164 },
        { lat: 41.325180, lng: 19.825144 },
        { lat: 41.319879, lng: 19.826556 },
        { lat: 41.316344, lng: 19.814869 },
        { lat: 41.319997, lng: 19.806085 },
        { lat: 41.328950, lng: 19.802398 },
        { lat: 41.333426, lng: 19.804751 }
    ]);
    const [polygonHistory, setPolygonHistory] = useState([]);
    
    const [timeSlots, setTimeSlots] = useState({
      Monday: [null, null],
      Tuesday: [null, null],
      Wednesday: [null, null],
      Thursday: [null, null],
      Friday: [null, null],
      Saturday: [null, null],
      Sunday: [null, null],
    });

    const formatTime = (time) => {
      if (!time) return 'Free'; 
      return time; // Return the time as it comes from the API
    }

    useEffect(() => {
        if (passedZone && passedZone.path) {
            const newPath = passedZone.path.map(coord => ({
                lat: coord.latitude,
                lng: coord.longitude
            }));
            setPath(newPath);
            setName(passedZone.name);
            setCurrency(passedZone.price.currency);
            setPrice(passedZone.price.amount);
            setColor(passedZone.color);
            ///setHoles(passedZone.holes);
          //   const initialTimeSlots  = { };
          //   const timezone = passedZone.city.timezone_id;
          //   Object.entries(passedZone.price.rules).forEach(([day, { start_time, end_time }]) => {
          //     initialTimeSlots[day] = [
          //       formatTime(start_time, timezone),
          //       formatTime(end_time, timezone)
          //     ];
          // });
          const initialTimeSlots = dayOrder.reduce((acc, day) => {
            const dayData = passedZone.price.rules[day];
            return {
                ...acc,
                [day.charAt(0).toUpperCase() + day.slice(1)]: [
                    dayData ? formatTime(dayData.start_time) : null,
                    dayData ? formatTime(dayData.end_time) : null
                ]
            };
        }, {});
          setTimeSlots(initialTimeSlots);
        }
    }, [passedZone]);

    useEffect(() => {
      const fetchZones = async () => {
          try {
            console.log("Fetching zones...");
              const response = await axiosInstance.get('/partner_entity/parking/');
              setZones(response.data.zones || []);
              setIsLoading(false);
          } catch (error) {
            if(error.response){
              console.error('Failed to fetch zones in response:', error.response.data);
            }else if(error.request){
              console.error('Failed to fetch zones in request:', error.request);
            }else{
              console.error('Failed to fetch zones:', error.message);
            }
              setIsLoading(false);
          }
      };

      if (isLoaded) {
        fetchZones();
    }
     }, [isLoaded]);
   
    const addHoleSelection = () => {
      setHoleSelections([...holeSelections, { id: "", path: [] }]);
    };

    // Utility to convert coordinate array to a sorted string key
    function coordsToString(coordinates) {
      return coordinates.map(coord => `${coord.latitude.toFixed(5)},${coord.longitude.toFixed(5)}`).sort().join('|');
    }

    // Function to find if two sets of coordinates are equal
    function areCoordsEqual(coords1, coords2) {
      const string1 = coordsToString(coords1);
      const string2 = coordsToString(coords2);
      return string1 === string2;
    }

    useEffect(() => {
      const matchHolesToZones = () => {
          // Default to an empty selection if there are no holes
          let holeMatches = [];
          if (passedZone.holes && passedZone.holes.length > 0) {
              holeMatches = passedZone.holes.map(hole => {
                  const matchedZone = zones.find(zone => areCoordsEqual(hole, zone.path));
                  return {
                      hole,
                      zoneId: matchedZone ? matchedZone.id : "unmatched"  // Use "unmatched" if no zone matches
                  };
              });
          }
  
          // Always ensure there is at least one editable entry
          if (holeMatches.length === 0) {
              holeMatches.push({ hole: [], zoneId: "unmatched" });
          }
  
          // Update state with matched holes (or unmatched if no zoneId)
          setHoleSelections(holeMatches.map(match => ({
              id: match.zoneId,
              path: match.hole
          })));
      };
  
      if (zones.length > 0 && passedZone) {
          matchHolesToZones();
      }
    }, [zones, passedZone]); 
  
  const removeHoleSelection = (index) => {
      if (holeSelections.length > 1) {
          setHoleSelections(holeSelections.filter((_, i) => i !== index));
      } else {
        setHoleSelections([{ id: "unmatched", path: [] }]);
      }
    };

    
  const handleTimeChange = (day, index, value) => {
          setTimeSlots(prev => ({
              ...prev,
              [day]: [
                  ...prev[day].slice(0, index),
                  value,
                  ...prev[day].slice(index + 1)
              ]
          }));
      };


  const handleButtonClick = async () => {
      const zoneName = document.querySelector('input[placeholder="Zone Name"]').value;
      const price = document.querySelector('input[placeholder="Price"]').value;

      const timeRules = {};
        Object.entries(timeSlots).forEach(([day, times]) => {
        if (times[0] && times[1] && times[0] !== 'Free' && times[1] !== 'Free') {
            timeRules[day.toLowerCase()] = {
            start_time: times[0] ,
            end_time: times[1]
          };
        } else {
          timeRules[day.toLowerCase()] = 
          { start_time: null,
            end_time: null };
          }
      });

      const zonePath = polygonRef.current.getPath().getArray().map(({ lat, lng }) => ({
          latitude: lat(),
          longitude: lng()
      }));

      const updatedHoles = [];
      for (let selection of holeSelections) {
        if (selection.id && zones.length > 0) {
          const selectedZone = zones.find(zone => zone.id.toString() === selection.id.toString());
          if (selectedZone) {
              const holeCoordinates = selectedZone.path.map(coord => [coord.longitude, coord.latitude]);
              holeCoordinates.push(holeCoordinates[0]);
              const holePolygon = turf.polygon([holeCoordinates]);
              const outerPolygonCoordinates = zonePath.map(coord => [coord.longitude, coord.latitude]);
              outerPolygonCoordinates.push(outerPolygonCoordinates[0]);
              const outerPolygon = turf.polygon([outerPolygonCoordinates]);
              const isValidHole = turf.booleanContains(outerPolygon, holePolygon);
              if (isValidHole) {
                console.log("the hole is valid:", holePolygon);
                  updatedHoles.push(selectedZone.path);
              } else {
                console.log("the hole is valid:", holePolygon);
                  alert(`Hole with ID ${selection.id} is not valid and will not be included.`);
              }
          }
        }
      }
      const requestBody = {
          name: zoneName,
          slug: passedZone.slug,
          price: {
          amount: parseInt(price),
          currency: currency,
          rules: timeRules
          },
        available_slots: 500,
        path: zonePath,
        holes: updatedHoles,
        ////city_id:passedZone.city.id,
        is_active:passedZone.is_active,
        color:color
      };
    console.log(JSON.stringify(requestBody));

    try {
      const response = await axiosInstance.patch(`admin/zones/${passedZone.id}/`, requestBody);
          console.log('Zone updated successfully', response.data);
          alert("Zone updated successfully!");
          history.push('/dashboardmap');
    } catch (error) {

      if(error.response){
        console.error('Failed to updated zone:', error.response.data);
        alert('Failed to update zone.');
      }else if (error.request){
        console.error("Error updating zone:", error.request);
      }else{
        console.error("Error:", error.message);
      }
      alert("Failed to update zone. Please try again.");
    }
    };   

  const handleZoneSelect =  (index,event) => {
    event.preventDefault();
    const newZoneId = event.target.value;
  const updatedHoleSelections = holeSelections.map((selection, idx) => {
    if (idx === index) {
      return { ...selection, id: newZoneId };
    }
    return selection;
  });
  setHoleSelections(updatedHoleSelections);

// Fetch zone details based on selected ID
  const zone = zones.find(z => z.id.toString() === newZoneId);
  if (zone && polygonRef.current) {
      const holeCoordinates = zone.path.map(coord => [coord.longitude, coord.latitude]);

  // Ensure the coordinates form a closed loop
  if (holeCoordinates.length > 0 && (holeCoordinates[0][0] !== holeCoordinates[holeCoordinates.length - 1][0] ||
      holeCoordinates[0][1] !== holeCoordinates[holeCoordinates.length - 1][1])) {
      holeCoordinates.push(holeCoordinates[0]);
  }

  const holePolygon = turf.polygon([holeCoordinates]);
  const outerPolygonCoordinates = polygonRef.current.getPath().getArray().map(p => [p.lng(), p.lat()]);

  // Close the loop for outer polygon coordinates
  if (outerPolygonCoordinates.length > 0 && (outerPolygonCoordinates[0][0] !== outerPolygonCoordinates[outerPolygonCoordinates.length - 1][0] ||
      outerPolygonCoordinates[0][1] !== outerPolygonCoordinates[outerPolygonCoordinates.length - 1][1])) {
      outerPolygonCoordinates.push(outerPolygonCoordinates[0]);
  }

  const outerPolygon = turf.polygon([outerPolygonCoordinates]);

  try {
      const isValidHole = turf.booleanContains(outerPolygon, holePolygon);
      if (isValidHole) {
          console.log(`Zone ${zone.id} is a valid hole.`);
          // Optional: Trigger any additional UI update or state change
      } else {
          console.error(`Zone ${zone.id} is not a valid hole.`);
          alert(`Zone ${zone.name} cannot be added as a hole.`);
          // Reset the dropdown value for this particular dropdown
          updatedHoleSelections[index] = { ...updatedHoleSelections[index], id: "" };
          setHoleSelections(updatedHoleSelections);
      }
  } catch (error) {
      console.error("Error in creating polygons with Turf.js:", error);
      alert("Error processing the selected zone. Please check the console for details.");
      // Reset the dropdown value for this particular dropdown
      updatedHoleSelections[index] = { ...updatedHoleSelections[index], id: "" };
      setHoleSelections(updatedHoleSelections);
  }
  }
  };

  const resetHoleSelections = () => {
    setHoleSelections([{ id: "unmatched", path: [] }]); // Reset to initial state or clear entirely
  };

  const handleUndo = () => {
    console.log("Undo button clicked");
    if (polygonHistory.length > 1) { // Ensure there's a previous state to revert to
        const previousPath = polygonHistory[polygonHistory.length - 2]; // Get the second last item
        console.log("Previous path from history:", previousPath);
        if (polygonRef.current) {
            const google = window.google;
            const newPath = previousPath.map(coord => new google.maps.LatLng(coord.lat, coord.lng));
            console.log("New Path LatLng objects:", newPath);
            polygonRef.current.setPath(newPath);

            // Verify if the path is set correctly
            const currentPath = polygonRef.current.getPath().getArray().map(latLng => ({
                lat: latLng.lat(), lng: latLng.lng()
            }));
            console.log("Current polygon path after undo:", currentPath);

            // Remove the last entry from history after undo
            setPolygonHistory(polygonHistory.slice(0, -1));
        }
    } else {
        console.log("No more actions to undo.");
        alert("No more actions to undo.");
    }
};

  useEffect(() => {
    if (!isLoaded || loadError) return;
      console.log("Initializing Google Maps...");
      const google = window.google;
    if (google) {
        const map = new google.maps.Map(mapRef.current, {
            center: { lat: latitude, lng: longitude },
            zoom: 14
        });
        polygonRef.current = new google.maps.Polygon({
            paths: path,
            editable: true,
            draggable: true,
            fillColor: color,
            fillOpacity: 0.2,
            strokeColor: '#000000',
            strokeOpacity: 0.8,
            strokeWeight: 2
        });
        
        setPolygonHistory([path]);
        polygonRef.current.setMap(map);      

        google.maps.event.addListener(polygonRef.current, 'mouseup', () => {
          const currentPath = polygonRef.current.getPath().getArray().map(latLng => ({
              lat: latLng.lat(), lng: latLng.lng()
          }));
      
          setPolygonHistory(prev => {
              const newHistory = [...prev, currentPath];
              console.log("Updated polygon history:", newHistory);
              return newHistory;
          });
      
          resetHoleSelections();
          console.log("Polygon updated:", currentPath);
      });

              // Filter out the main polygon from zones
              const filteredZones = zones.filter(zone => {
                // Replace 'mainPolygonId' with the actual identifier or condition to exclude the main polygon
                return zone.id !== passedZone.id;
            });

      filteredZones.forEach(zone => {
        const zonePolygon = new google.maps.Polygon({
            paths: zone.path.map(point => ({ lat: point.latitude, lng: point.longitude })),
            holes: zone.holes.map(hole => hole.map(point => ({ lat: point.latitude, lng: point.longitude }))),
            editable: false,
            draggable: false,
            fillColor: 'grey',
            fillOpacity: 0.1,
            strokeColor: 'grey',
            strokeOpacity: 1,
            strokeWeight: 2,
            zIndex: 1
        });

        zonePolygon.setMap(map);
    });

        return () => {
          console.log("Cleaning up Google Maps...");
          if (polygonRef.current) {
            polygonRef.current.setMap(null);
        }
        zones.forEach(zone => {
            if (zone.polygon) {
                zone.polygon.setMap(null);
            }
        });
        };
    }
  }, [isLoaded, loadError, path, zones, latitude, longitude]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps...</div>;

    return (
        <div className="container-map-create">
          <div >
            <NavBar />
          </div>
          <div className="map-create" ref={mapRef}></div>
          <div className="controls-map-create">
            <h2>Place zone information here</h2>
            <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Zone Name" />
            <input type="text" value={price} onChange={e => setPrice(e.target.value)} placeholder="Price" />
            {holeSelections.map((selection, index) => (
                <div key={index} className="hole-selection-container">
                    <select
                        value={selection.id}
                        onChange={(event) => handleZoneSelect(index, event)}
                        disabled={isLoading}
                        className="hole-dropdown"
                    >
                        {/* <option value="unmatched">{selection.id === "unmatched" ? "Unmatched Hole" : "Select Zone"}</option> */}
                        <option value="unmatched">Select Sub-Zone</option>
                            {zones.filter(zone => zone.id !== passedZone.id).map(zone => (
                                <option key={zone.id} value={zone.id}>{zone.name}</option>
                            ))}
                    </select>
                    <div className="hole-action-buttons">
                        <span className="add-hole-button" onClick={() => addHoleSelection()}>+</span>
                        {holeSelections.length > 1 && (
                            <span className="remove-hole-button" onClick={() => removeHoleSelection(index)}>-</span>
                        )}
                    </div>
                </div>
            ))}
            <div className="rules">
              <h3>Rules:</h3>
              {Object.entries(timeSlots).map(([day, times], index) => (
                        <div key={index}>
                            <label>{day.charAt(0).toUpperCase() + day.slice(1)} :</label>
                            <div>
                                <input
                                    type="time"
                                    value={times[0] || ""}
                                    onChange={e => handleTimeChange(day, 0, e.target.value)}
                                />
                                <input
                                    type="time"
                                    value={times[1] || ""}
                                    onChange={e => handleTimeChange(day, 1, e.target.value)}
                                />
                            </div>
                        </div>
                    ))}
            </div>
            <div className="color-picker">
                    <h4>Pick a color:</h4>
                    <div className="sketch-picker-wrapper">
                        <SketchPicker
                            color={color}
                            onChangeComplete={(newColor) => setColor(newColor.hex)}
                        />
                    </div>
                </div>
            <button className="undo-button-map" onClick={handleUndo}>
                    Undo
            </button>
            <button className="submit-save-button" onClick={handleButtonClick}>
              Save
            </button>
          </div>
        </div>
      );
    };


export default MapEdit;
