import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import './Navbar.css';
import homeicon from './img/home5.svg';
import createzoneicon from './img/createzone5.svg';
import parkingsessionsicon from './img/parking5.svg';
import statsicon from './img/stats5.svg';
import menuicon from './img/menu5.svg';
import logouticon from './img/logout5.svg';
import streeticon from './img/street5.svg';
import pinicon from './img/pin5.svg';
import exportpdf from './img/export.svg';
import logo from './img/logonew.png'
import usersicon from './img/partners.svg'


const Navbar = ({handleTheClick}) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const location = useLocation(); 
    const username = localStorage.getItem('username');
    const specificPath = '/dashboardmap';
    const toggleNavbar = () => {
      setIsExpanded(!isExpanded);
    };
  
    const handleImageClick = (event) => {
      event.stopPropagation(); // Prevent the navbar from expanding when clicking on the image
    };
  
    const getActiveClass = (path) => {
      return location.pathname === path ? 'active' : '';
    };
    const handleBothFunctions = () => {
      // handleImageClick();
      handleTheClick();
    };
  
    return (
      <nav className={`navbar-menu ${isExpanded ? 'expanded' : ''}`}>
      <div className="navbar-header">
        <div className="burger" onClick={toggleNavbar}>
          <img src={menuicon} alt="menu" />
        </div>
      </div>
        <ul className="navbar__list">
          <li className={`navbar__li-box ${getActiveClass('/dashboardmap')}`}>
            <Link to="/dashboardmap">
              <img src={homeicon} alt="Home" onClick={handleImageClick} />
              {isExpanded && <span className="navbar-link">Home</span>}
            </Link>
          </li>
          <li className={`navbar__li-box ${getActiveClass('/create-zone')}`}>
            <Link to="/create-zone">
              <img src={createzoneicon} alt="Create Zone" onClick={handleImageClick} />
              {isExpanded && <span className="navbar-link">Create Zone</span>}
            </Link>
          </li>
          <li className={`navbar__li-box ${getActiveClass('/create-street')}`}>
            <Link to="/create-street">
              <img src={streeticon} alt="Create Street" onClick={handleImageClick} />
              {isExpanded && <span className="navbar-link">Create Street</span>}
            </Link>
          </li>
          <li className={`navbar__li-box ${getActiveClass('/create-pin')}`}>
          <Link to="/create-pin">
              <img src={pinicon} alt="Create Pin" onClick={handleImageClick} />
              {isExpanded && <span className="navbar-link">Create Pin</span>}
          </Link>
          </li>
          <li className={`navbar__li-box ${getActiveClass('/parkingsessions')}`}>
            <Link to="/parkingsessions">
              <img src={parkingsessionsicon} alt="Parking Sessions" onClick={handleImageClick} />
              {isExpanded && <span className="navbar-link">Parking Sessions</span>}
            </Link>
          </li>
          <li className={`navbar__li-box ${getActiveClass('/stats')}`}>
            <Link to="/stats">
              <img src={statsicon} alt="Statistics" onClick={handleImageClick} />
              {isExpanded && <span className="navbar-link">Statistics</span>}
            </Link>
          </li>
          <li className={`navbar__li-box ${getActiveClass('/users')}`}>
            <Link to="/users">
              <img src={usersicon} alt="Users" onClick={handleImageClick} />
              {isExpanded && <span className="navbar-link">Users</span>}
            </Link>
          </li>
          <li className={`navbar__li-box ${getActiveClass('/')}`}>
              <img src={exportpdf} alt="Export" />
              <button className='handleTheclick' onClick={location.pathname === specificPath ? handleBothFunctions : null} 
              style={{ background: 'none', border: 'none', 
              padding: 0, cursor: location.pathname === specificPath ? 'pointer' : 'not-allowed', alignItems: 'center'}}
              disabled={location.pathname !== specificPath}
              >
              {isExpanded && <span className="navbar-link" style={{ paddingTop: '30px' }}>Export Map</span>}
              </button>
          </li>
          <li className={`navbar__li-box ${getActiveClass('/')}`}>
            <Link to="/logout">
              <img src={logouticon} alt="Logout" onClick={handleImageClick} />
              {isExpanded && <span className="navbar-link">Logout</span>}
            </Link>
          </li>
        </ul>
        <div className="user-info">
        <FaUserCircle className= "user-info-icon" size={30} color="#f8f8ff" />
        {isExpanded && <span className="username">{username}</span>}
        </div>
        {isExpanded && (
        <div className="navbar-footer">
          <img src={logo} alt="Company Logo" className="company-logo" />
          <div className="company-info">
            <p>Design & developed by <a href="https://artech-software.com/" target="_blank" rel="noopener noreferrer">Parknexa</a></p>
            <p>Contact: info@parknexa.com</p>
            <p className='footer-rights'>All rights reserved,2024®</p>
          </div>
        </div>
      )}
      </nav>
    );
  };
  
  
  export default Navbar;