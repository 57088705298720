import React, { useState, useCallback, useRef, useEffect } from 'react';
import { GoogleMap, Polyline } from "@react-google-maps/api";
import { useHistory, useLocation } from 'react-router-dom';
import '../CreatePolyline/Polyline.css';
import NavBar from "../Navbar/Navbar.js";
import axiosInstance from '../axiosConfig';

const EditPolyline = () => {
    const mapRef = useRef();
    const history = useHistory();
    const location = useLocation();
    const [currentLine, setCurrentLine] = useState([]);
    const defaultCityPath = { latitude: 41.3275, longitude: 19.8187 };
    const storedCityPath = localStorage.getItem('cityPath');
    const [line, setLine] = useState(null);
    const [streetName, setStreetName] = useState('');
    const [availableSlots, setAvailableSlots] = useState('');
    const passedStreet = location.state ? location.state.street : null;
    const [mapCenter] = useState(() => {
        let cityPath;
        try {
            cityPath = storedCityPath ? JSON.parse(storedCityPath) : defaultCityPath;
        } catch (error) {
            console.error("Error parsing cityPath from localStorage", error);
            cityPath = defaultCityPath;
        }
        return { lat: cityPath.latitude, lng: cityPath.longitude };
    });
    const options = {
        clickableIcons: false,
        disableDefaultUI: true,
        zoomControl: true,
        gestureHandling: "greedy"
    };

    useEffect(() => {
        if (passedStreet && passedStreet.path) {
            const newPath = passedStreet.path.map(coord => ({
                lat: coord.lat,
                lng: coord.lng
            }));
            setCurrentLine(newPath);
            setStreetName(passedStreet.name);
            setAvailableSlots(passedStreet.available_slots);
        }
    }, [passedStreet]);

    const onMapClick = useCallback((event) => {
        const newPoint = { lat: event.latLng.lat(), lng: event.latLng.lng() };
        setCurrentLine(prev => [...prev, newPoint]);
    }, []);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.addListener('click', onMapClick);
        }
    }, [onMapClick]);

    const handleFinishLine = () => {
        if (currentLine.length > 1) {
            setLine({ points: currentLine });
            setCurrentLine([]);
        }
    };

    const handleClearLine = () => {
        setLine(null);
        setCurrentLine([]);
        setTimeout(() => window.location.reload(), 50);
    };

    const handleUndo = () => {
        setCurrentLine(currentLine.slice(0, -1));
    };

    const generateSlug = (name) => {
        return name.toLowerCase().replace(/\s+/g, '-');
    };

    const PostLine = async () => {
        const requestBody = {
            name: streetName,
            slug: generateSlug(streetName),
            path: line.points.map(point => ({ latitude: point.lat, longitude: point.lng })),
            available_slots: parseInt(availableSlots),
            ///city_id: passedStreet.city.id
        };
        console.log(JSON.stringify(requestBody));
        try {
            await axiosInstance.patch(`admin/streets/${passedStreet.id}/`, requestBody);
            alert('Street updated successfully!');
            history.push('/dashboardmap');
        } catch (error) {
            if (error.response) {
                console.error('Error updating Street:', error.response.data);
            } else if (error.request) {
                console.error('Error updating Street:', error.request);
            } else {
                console.error('Error', error.message);
            }
            alert('Error updating Street!');
        }
    };

    return (
        <div className="container-map">
            <NavBar />
            <div className="controls">
                <h1>Edit Street</h1>
                <input
                    className='input-polyline-text'
                    type="text"
                    placeholder="Street Name"
                    value={streetName}
                    onChange={e => setStreetName(e.target.value)}
                />
                <input
                    className='input-polyline-number'
                    type="number"
                    placeholder="Available Slots"
                    value={availableSlots}
                    onChange={e => setAvailableSlots(e.target.value)}
                />
                <button className="btn-finishline" onClick={handleFinishLine} disabled={currentLine.length < 2}>Finish Line</button>
                <button className="btn-undo" onClick={handleUndo} disabled={currentLine.length === 0}>Undo</button>
                <button className="btn-clear" onClick={handleClearLine}>Clear Line</button>
                {line && (
                    <div className="line-details">
                        <p><strong>Street:</strong> {streetName}</p>
                        <p><strong>Available Slots:</strong> {availableSlots}</p>
                        <p><strong>City:</strong> {passedStreet.city.name}</p>
                        <div className="active-street-status">
                        <strong>Active Status:</strong> {passedStreet.is_active ? "Active" : "Inactive"}
                        </div>
                    </div>
                )}
                {line && <button className="btn-saveline" onClick={PostLine}>Save</button>}
            </div>
            <div className="map">
                <GoogleMap
                    zoom={14}
                    center={mapCenter}
                    mapContainerClassName="container-map"
                    onLoad={map => {
                        mapRef.current = map;
                        mapRef.current.addListener('click', onMapClick);
                    }}
                    options={options}
                >
                    {line && (
                        <Polyline
                            path={line.points}
                            options={{
                                strokeColor: "#FF0000",
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                clickable: false,
                                editable: false
                            }}
                        />
                    )}
                    {currentLine.length > 1 && (
                        <Polyline
                            path={currentLine}
                            options={{
                                strokeColor: "#00FF00",
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                clickable: false,
                                editable: false
                            }}
                        />
                    )}
                </GoogleMap>
            </div>
        </div>
    );
};

export default EditPolyline;
