import axios from 'axios';

// Create an instance of axios
const axiosInstance = axios.create({
  baseURL : 'https://staging.parknexa.com/api/',
  /////baseURL: 'http://127.0.0.1:8000/api/', 
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add a request interceptor to include the token in the headers
axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;