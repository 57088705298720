import { useLoadScript } from '@react-google-maps/api';
import EditPin from "./EditPin.js"



export default function EditPins(){
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyA-z2alf2wbqaRxNAMcrGd8QqZ71wd0zo0",

    });

    if(!isLoaded) return <div>Loading....</div>;

    return (
        <div>
            {isLoaded ? (
                <div>
                    <EditPin />
                </div>
            ) : (
                <div>Loading....</div>
            )}
        </div>
    );
}