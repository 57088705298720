import  {  useEffect } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import MapEdit from "./EditMap.js"


export default function MapPageEdit(){
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyA-z2alf2wbqaRxNAMcrGd8QqZ71wd0zo0",

    });

    useEffect(() => {
        return () => {
            console.log('MapPageEdit unmounting...');
        };
    }, []);

    if(!isLoaded) return <div>Loading....</div>;

    return (
        <div>
            {isLoaded ? (
                <div>
                    <MapEdit />
                </div>
            ) : (
                <div>Loading....</div>
            )}
        </div>
    );
}
