import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig';
import Navbar from "../Navbar/Navbar.js";
import * as XLSX from 'xlsx';
import './Users.css';
import { FaTrashAlt, FaEdit  } from 'react-icons/fa';
import UserRegistrationModal from './UserModal.js'

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filterName, setFilterName] = useState('');
    const [filterEmail, setFilterEmail] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filterLastName, setFilterLastName] = useState('');
    const [filterIsStaff, setFilterIsStaff] = useState('');
    const [filterIsPolice, setFilterIsPolice] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axiosInstance.get('admin/partner_entity/staff/'); 
                setUsers(response.data || []);
                setFilteredUsers(response.data || []);
                setIsLoading(false);
            } catch (error) {
                console.error('Failed to fetch users:', error);
                setError('Failed to load users. Try again later.');
                setIsLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const applyFilters = () => {
        let filtered = users.filter(user => {
            return (
                (filterName === '' || user.first_name.toLowerCase().includes(filterName.toLowerCase())) &&
                (filterLastName === '' || user.last_name.toLowerCase().includes(filterLastName.toLowerCase())) &&
                (filterEmail === '' || user.email.toLowerCase().includes(filterEmail.toLowerCase())) &&
                (filterIsStaff === '' || user.is_staff === (filterIsStaff === 'true')) &&
                (filterIsPolice === '' || user.is_police === (filterIsPolice === 'true'))
            );
        });
        setFilteredUsers(filtered);
    };

    const clearFilters = () => {
        setFilterName('');
        setFilterEmail('');
        setFilterLastName('');
        setFilterIsStaff('');
        setFilterIsPolice('');
        setFilteredUsers(users);
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.table_to_sheet(document.querySelector('.table'));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
        XLSX.writeFile(workbook, 'users_list.xlsx');
    };

    const createUser = () =>{
        setSelectedUser(null);
        setIsModalOpen(true);
    }

    const editUser = (user) => {
        setSelectedUser(user);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const deleteUser = async (userId) => {
        if (window.confirm("Are you sure you want to delete this user?")) {
            try {
                await axiosInstance.delete(`admin/partner_entity/staff/${userId}/`);
                setFilteredUsers(filteredUsers.filter(user => user.id !== userId));
                setUsers(users.filter(user => user.id !== userId));
            } catch (error) {
                console.error('Failed to delete user:', error);
                alert('Failed to delete user. Try again later.');
            }
        }
    };

    const addUser = (newUser) => {
        setUsers([...users, newUser]);
        setFilteredUsers([...users, newUser]);
    };

    const updateUser = (updatedUser) => {
        setUsers(users.map(user => user.id === updatedUser.id ? updatedUser : user));
        setFilteredUsers(filteredUsers.map(user => user.id === updatedUser.id ? updatedUser : user));
    };

    return (
        <div className="container-users">
            <div className="navbar-left-users">
                <Navbar />
            </div> 
            {isLoading && <p>Loading...</p>}
                {error && <p>{error}</p>}
                {!isLoading && !error &&(
            <div className='content-users'>
                <div className='users-header'>
                    <h1>Users List</h1>
                    <button className="export-to-excel-button-users" onClick={exportToExcel}>Export to Excel</button>
                </div>
                <div className="filters-users">
                    <div className="filter-row-users">
                        <div className="filter-group-users">
                            <label htmlFor="filterName">Search by name</label>
                            <input
                                id="filterName"
                                type="text"
                                placeholder="Search by name"
                                value={filterName}
                                onChange={(e) => setFilterName(e.target.value)}
                            />
                        </div>
                    <div className="filter-group-users">
                        <label htmlFor="filterLastName">Search by last name</label>
                        <input
                        id="filterLastName"
                        type="text"
                        placeholder="Search by last name"
                        value={filterLastName}
                        onChange={(e) => setFilterLastName(e.target.value)}
                    />
                    </div>
                        <button className="filter-button apply-button" onClick={applyFilters}>Apply</button>
                    </div>
                    <div className="filter-row-users">
                        <div className="filter-group-users">
                            <label htmlFor="filterEmail">Search by email</label>
                            <input
                                id="filterEmail"
                                type="text"
                                placeholder="Search by email"
                                value={filterEmail}
                                onChange={(e) => setFilterEmail(e.target.value)}
                            />
                        </div>
                        <div className="filter-group-users">
                                <label htmlFor="filterIsStaff">Filter by Staff User</label>
                                <select
                                    id="filterIsStaff"
                                    value={filterIsStaff}
                                    onChange={(e) => setFilterIsStaff(e.target.value)}
                                >
                                    <option value="">All</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </div>
                            <div className="filter-group-users">
                                <label htmlFor="filterIsPolice">Filter by Police User</label>
                                <select
                                    id="filterIsPolice"
                                    value={filterIsPolice}
                                    onChange={(e) => setFilterIsPolice(e.target.value)}
                                >
                                    <option value="">All</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </div>
                        <button className="filter-button clear-button-users" onClick={clearFilters}>Clear all</button>
                    </div>
                </div>
                <div className="table-container">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Partner Name</th>
                                <th>City</th>
                                <th>Staff User</th>
                                <th>Police User</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.map(user => (
                                <tr key={user.id}>
                                    <td>{user.first_name}</td>
                                    <td>{user.last_name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.partner_entity?.name || 'N/A'}</td>
                                    <td>{user.partner_entity?.city?.name || 'N/A'}</td>
                                    <td className={user.is_staff ? 'status-green' : 'status-red'}>
                                        {user.is_staff ? <span>&#10004;</span> : <span>&#10006;</span>}
                                    </td>
                                    <td className={user.is_police ? 'status-green' : 'status-red'}>
                                        {user.is_police ? <span>&#10004;</span> : <span>&#10006;</span>}
                                    </td>
                                    <td className="edit-cell">
                                            <FaEdit 
                                                onClick={() => editUser(user)} 
                                                style={{ cursor: 'pointer', color: 'blue'}}
                                            />
                                    </td>
                                    <td className="checkbox-cell">
                                            <FaTrashAlt 
                                                onClick={() => deleteUser(user.id)} 
                                                style={{ cursor: 'pointer', color: 'red' }}
                                            />
                                        </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="user-effects-buttons">
                    <button className='user-buttons' onClick={createUser}>Create User</button>
                 </div>
            </div>
            )}
            <UserRegistrationModal isOpen={isModalOpen} 
            onRequestClose={closeModal} 
            addUser={addUser}
            updateUser={updateUser} 
            selectedUser={selectedUser}
            />
        </div>
    );
};

export default UserList;
