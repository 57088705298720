import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'; 
import './Login.css'; 
import axiosInstance from '../axiosConfig';


function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestBody={        
      username: email,
      password: password};
    try{
      const response = await axiosInstance.post('user/signin/', requestBody);
      if(response.data.token)
        {
          localStorage.setItem('authToken', response.data.token);
        }      
       if (response.data.partner_entity) {
        const partner_response = response.data.partner_entity;
        localStorage.setItem('cityId', partner_response.city.id);
        const mapCenterLocation = partner_response.map_center_location;
        if (mapCenterLocation) {
          localStorage.setItem('cityPath', JSON.stringify(mapCenterLocation));
      } else {
          console.error('map_center_location is undefined or not an array');
      }
        localStorage.setItem('partnerType', partner_response.type);
        localStorage.setItem('partnerName',partner_response.name);
        localStorage.setItem('username',response.data.username);
    }
       console.log('Succesfully login :');
       history.push('/dashboardmap');
    }catch(error){
      if (error.response) {
        console.error('Failed to login :', error.response.data);
      } else if (error.request) {      
        console.error('Failed to login:', error.request);
      } else {
      console.error('Error', error.message);
      }
        alert('Failed to login Check your credentials!');
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <div className="logo-container">
                <h1 className="site-name">ParkNexa - Municipalities</h1>
                <img src="/logonew.png" alt="Logo" className="logo" />
              </div>
              {/* <h3 className="card-title mb-4">Login</h3> */}
              <form >
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input type="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">Password</label>
                  <input type="password" className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </div>
                <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Login</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
