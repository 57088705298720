import React, { useState, useEffect  } from 'react';
import axiosInstance from '../axiosConfig';
import './UserRegistrationForm.css';

const UserRegistrationForm = ({ onCancel, addUser, updateUser, selectedUser   }) => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        user_email: '',
        is_staff: false,
        is_police: false,
        user_password: ''
    });

    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');


    useEffect(() => {
        if (selectedUser) {
            setFormData({
                first_name: selectedUser.first_name || '',
                last_name: selectedUser.last_name || '',
                user_email: selectedUser.email || '',
                is_staff: selectedUser.is_staff || false,
                is_police: selectedUser.is_police || false,
                user_password: '',
            });
        }
    }, [selectedUser]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e) => {
        const dataToSend = { ...formData };
        e.preventDefault();

        if (selectedUser) {
            delete dataToSend.user_password; // Remove password field if editing user
        }
        try {
            if (selectedUser) {
                console.log(JSON.stringify())
                const response = await axiosInstance.put(`admin/partner_entity/staff/`, dataToSend);
                setMessage('User updated successfully');
                setSuccess(true);
                updateUser(response.data); // Call the updateUser function with the updated user data
            } else {
                const response = await axiosInstance.put('admin/partner_entity/staff/', dataToSend);
                setMessage('User created successfully');
                setSuccess(true);
                addUser(response.data); // Call the addUser function with the new user data
            }
            setFormData({
                first_name: '',
                last_name: '',
                user_email: '',
                is_staff: false,
                is_police: false,
                user_password: '',
            });
        } catch (error) {
            setMessage('Failed to save user');
            setSuccess(false);
        }
    };
    return (
        <div className="user-registration-form-container">
            <h2>New User</h2>
            {message && (
                <p className={`message ${success ? 'success' : 'error'}`}>
                    {success && <span className="check-mark">✔</span>} {message}
                </p>
            )}
            <form className="user-registration-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="first_name">First Name</label>
                    <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="last_name">Last Name</label>
                    <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="user_email">Email</label>
                    <input
                        type="email"
                        id="user_email"
                        name="user_email"
                        value={formData.user_email}
                        onChange={handleChange}
                        required
                    />
                </div>
                {!selectedUser && (
                <div className="form-group">
                    <label htmlFor="user_password">Password</label>
                    <input
                        type="password"
                        id="user_password"
                        name="user_password"
                        value={formData.user_password}
                        onChange={handleChange}
                        required
                    />
                </div>
                )}
                <div className="form-section">
                    <h3>Roles</h3>
                    <div className="checkbox-group">
                        <div className="checkbox-group-item">
                            <label>
                                <input
                                    type="checkbox"
                                    name="is_staff"
                                    checked={formData.is_staff}
                                    onChange={handleChange}
                                />
                                Staff User
                            </label>
                        </div>
                        <div className="checkbox-group-item">
                            <label>
                                <input
                                    type="checkbox"
                                    name="is_police"
                                    checked={formData.is_police}
                                    onChange={handleChange}
                                />
                                Police User
                            </label>
                        </div>
                    </div>
                </div>
                <div className="form-buttons">
                    <button type="button" className="cancel-button-modal" onClick={onCancel}>Cancel</button>
                    <button type="submit" className="submit-button-modal">Create User</button>
                </div>
            </form>
        </div>
    );
};

export default UserRegistrationForm;
