// import React, { useState, useEffect } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import Map from "../MapComponents/map.js"



export default function MapPage(){
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyA-z2alf2wbqaRxNAMcrGd8QqZ71wd0zo0",

    });

    if(!isLoaded) return <div>Loading....</div>;

    return (
        <div>
            {isLoaded ? (
                <div>
                    <Map />
                </div>
            ) : (
                <div>Loading....</div>
            )}
        </div>
    );
}