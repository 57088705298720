// UserRegistrationModal.js
import React from 'react';
import Modal from 'react-modal';
import UserRegistrationForm from './UserRegistrationForm';

Modal.setAppElement('#root');

const UserRegistrationModal = ({ isOpen, onRequestClose, addUser, updateUser, selectedUser  }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="User Registration"
            className="Modal"
            overlayClassName="Overlay"
        >
            <UserRegistrationForm onCancel={onRequestClose} 
                addUser={addUser} 
                updateUser={updateUser} 
                selectedUser={selectedUser}/>
        </Modal>
    );
};

export default UserRegistrationModal;
